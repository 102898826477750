/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import i from "../../../core/Collection.js";
import { HighlightGroup as t } from "./HighlightGroup.js";
import n from "./HighlightOptions.js";
import { defaultHighlightName as o, temporaryHighlightName as e, temporaryHighlightColor as h } from "../../support/HighlightDefaults.js";
const s = i.ofType(t);
function g() {
  return new s([{
    name: o,
    options: new n()
  }, {
    name: e,
    options: new n({
      color: h
    })
  }]);
}
const l = g().items.map(i => i.name);
function r(i) {
  if (!i.highlights) return null;
  const t = i.highlights.findIndex(i => "default" === i.name);
  return -1 === t ? null : i.highlights.items[t].options;
}
function m(i, t) {
  if (!i.highlights) {
    if (!t) return;
    i.highlights = new s([{
      name: "default",
      options: new n()
    }]);
  }
  const o = i.highlights.findIndex(i => "default" === i.name);
  t ? i.highlights.items[o].options = t : i.highlights.removeAt(o);
}
export { l as admissibleHighlightGroupNames, g as createInitialHighlightGroups, r as getDefaultHighlightOptions, m as setDefaultHighlightOptions };