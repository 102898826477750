/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import r from "../../../core/Accessor.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as t } from "../../../core/accessorSupport/decorators/subclass.js";
import e from "./HighlightOptions.js";
var p;
let i = p = class extends r {
  constructor(...o) {
    super(...o), this.name = "default", this.options = new e();
  }
  clone() {
    return new p({
      name: this.name,
      options: this.options.clone()
    });
  }
};
o([s({
  type: String
})], i.prototype, "name", void 0), o([s({
  type: e
})], i.prototype, "options", void 0), i = p = o([t("esri.views.2d.support.HighlightGroup")], i);
export { i as HighlightGroup };